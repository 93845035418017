import { authentication, localStorageSafe } from '@SeLoger/authentication'
import { nameSpace } from './constantes'
import xhr from '../xhr/xhr'
import text from '../text'

function removeItemNC(attr) {
  if (!attr) {
    window.console.warn(`${text.LOGS} No Attr Provided for removeItemNC`)
    return Promise.reject('No Attr Provided')
  }

  const attrComplete = `${nameSpace}${attr}`
  localStorageSafe.removeItem(attrComplete)
  return Promise.resolve(attrComplete)
}

function removeItemC(attr) {
  if (!attr) {
    window.console.warn(`${text.LOGS} No Attr Provided for removeItemC`)
    return Promise.reject('No Attr Provided')
  }

  const url = `bigdata/users/keys/${attr}`
  return xhr.Delete(url)
}

const removeItem = attr => (
  authentication.isConnected() ? removeItemC(attr) : removeItemNC(attr)
)

export {
  removeItem,
  removeItemNC,
}
