import { authentication, localStorageSafe } from '@SeLoger/authentication'
import xhr from '../xhr/xhr'
import { nameSpace } from './constantes'
import text from '../text'

function setItemNC(attr, value, expireInDay) {
  const attrComplete = `${nameSpace}${attr}`
  const updateDate = new Date()
  let expireDate = new Date()
  if (expireInDay) {
    expireDate.setDate(expireDate.getDate() + parseInt(expireInDay, 10))
  } else {
    expireDate = undefined
  }

  const data = {
    value,
    expireDate,
    updateDate,
  }

  localStorageSafe.setItem(attrComplete, JSON.stringify(data))
  return Promise.resolve(attrComplete)
}

function setItemC(attr, value) {
  const url = 'bigdata/users/keys'
  const params = {
    [attr]: value,
  }

  return xhr.Post(url, params)
}

const setItem = (attr, value, expireInDay) => {
  if (!attr) {
    window.console.warn(`${text.LOGS} No Attr Provided for setItem`)
    return Promise.reject('No Attr Provided')
  }

  return authentication.isConnected() ? setItemC(attr, value) : setItemNC(attr, value, expireInDay)
}

function setListNC(params) {
  return Promise.all(
    Object.keys(params).map(item => setItemNC(
      item, params[item].value, params[item].expireInDay),
    ),
  )
}

function setListC(params) {
  const url = 'bigdata/users/keys'
  return xhr.Post(url, params)
}

const setList = data => (
  authentication.isConnected() ? setListC(data) : setListNC(data)
)

export {
  setItem,
  setList,
}
