import { authentication } from '@SeLoger/authentication'
import xhr from '../xhr/xhr'
import { getItem } from './getters'
import { setItem } from './setters'
import text from '../text'

// - use of stringify and parse : deduplication works both for primitive values and objects
// - limit number of items to the last "MAX_ITEMS"
const MAX_ITEMS = 15
const dedupeArrayValues = (array) => {
  const stringifiedArray = array.map(item => JSON.stringify(item))
  const result = stringifiedArray.filter(
    (value, index, self) => self.indexOf(value) === index,
  ).slice(-MAX_ITEMS)
  return result.map(item => JSON.parse(item))
}

const appendItemNC = (attr, value, expireInDay) => (
  getItem(attr).then((previousValues) => {
    const prev = previousValues || []
    const currentValues = Array.isArray(value) ?
      [...prev, ...value] : [...prev, value]
    return setItem(attr, dedupeArrayValues(currentValues), expireInDay)
  })
)

const appendItemC = (key, value) => {
  const url = `bigdata/users/keys/${key}`
  return xhr.Patch(url, value)
}

const appendItem = (attr, value, expireInDay) => {
  if (!attr) {
    window.console.warn(`${text.LOGS} No Attr Provided for appendItem`)
    return Promise.reject(new Error('No Attr Provided'))
  }

  return authentication.isConnected() ?
    appendItemC(attr, value) : appendItemNC(attr, value, expireInDay)
}

export {
  appendItem,
}
