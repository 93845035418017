import { authentication, localStorageSafe } from '@SeLoger/authentication'
import xhr from '../xhr/xhr'
import { nameSpace } from './constantes'
import { removeItem } from './remove'
import text from '../text'

function getItemNC(attr) {
  if (!attr) {
    window.console.warn(`${text.LOGS} No Attr Provided for getItemNC`)
    return Promise.reject('No Attr Provided')
  }

  let value
  const attrComplete = `${nameSpace}${attr}`

  const dataStringified = localStorageSafe.getItem(attrComplete)
  if (!dataStringified) return Promise.resolve('')

  const data = JSON.parse(dataStringified)
  const currentDate = Date.now()
  const expireDate = new Date(data.expireDate)
  if (expireDate && currentDate > expireDate) {
    removeItem(attr)
  } else {
    value = data.value
  }
  return Promise.resolve(value)
}

function getItemC(attr) {
  const mainSegment = 'bigdata/users'
  const params = {
    keys: attr,
  }
  const action = ''

  return xhr.Get(mainSegment, params, action)
}

const getItem = attr => (
  authentication.isConnected() ? getItemC(attr) : getItemNC(attr)
)


function getProjectItemsNC(projects) {
  return new Promise((resolve) => {
    const mainSegment = 'bigdata'
    const params = { projects }
    const action = '/keys/available'

    xhr.Get(mainSegment, params, action).then((data) => {
      const projectKeys = {}

      Promise.all(
        data.map(key => getItemNC(key).then((result) => { projectKeys[key] = result })),
      )
      .then(() => {
        resolve(projectKeys)
      })
    })
  })
}

function getProjectItemsC(projects) {
  const mainSegment = 'bigdata/users'
  const params = { projects }
  const action = '/keys'

  return xhr.Get(mainSegment, params, action)
}

const getProjectItems = project => (
  authentication.isConnected() ? getProjectItemsC(project) : getProjectItemsNC(project)
)

export {
  getItem,
  getProjectItems,
}
