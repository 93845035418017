import { authentication } from '@SeLoger/authentication'
import { getItem } from './getters'
import { setItem } from './setters'
import xhr from '../xhr/xhr'

const cancelItemC = (key, value) => {
  const url = `bigdata/users/keys/remove/${key}`
  return xhr.Patch(url, value)
}

const cancelItemNC = (attr, value, expireInDay) => (
  getItem(attr).then(previous => (
    setItem(attr, previous.filter(item => item !== value), expireInDay)
  ))
)

const cancelAppendItem = (attr, value, expireInDay) => (
  authentication.isConnected() ? cancelItemC(attr, value) : cancelItemNC(attr, value, expireInDay)
)

export {
  cancelAppendItem,
}
