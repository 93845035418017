import { authentication, localStorageSafe } from '@SeLoger/authentication'
import { nameSpace } from './constantes'
import { removeItemNC, removeItem } from './remove'
import { getItem, getProjectItems } from './getters'
import { setItem, setList } from './setters'
import { appendItem } from './append'
import { cancelAppendItem } from './cancel'

import text from '../text'

function pushListOnConnect() {
  if (!authentication.isConnected()) {
    console.warn(`${text.LOGS} Impossible de récupèrer les données car l'utilisateur n'est pas connecté.`)
    return
  }

  const params = {}

  Object.keys(window.localStorage).forEach((oneData) => {
    if (oneData.indexOf(nameSpace) > -1) {
      const key = oneData.replace(nameSpace, '')
      const oneDataObj = JSON.parse(localStorageSafe.getItem(oneData))
      params[key] = oneDataObj.value
    }
  })

  if (Object.keys(params).length) {
    setList(params).then(() => {
      Object.keys(params).forEach((key) => {
        removeItemNC(key)
      })
    })
  }
}

const nbdStorage = {
  getItem,
  setItem,
  setList,
  removeItem,
  pushListOnConnect,
  appendItem,
  getProjectItems,
  cancelAppendItem,
}

export default nbdStorage
