import axios from 'axios'
import { authentication } from '@SeLoger/authentication'
import text from '../text'

const apiUrl = Config.kitAPI
const API_KEY_NAME = 'Authorization'

function handleSuccess(response) {
  return response.data
}

function handleError(error) {
  return Promise.reject(new Error(error.message))
}

function setHeaders(config) {
  const { headers } = config
  headers['Content-type'] = 'application/json; charset=utf-8'
  if (authentication.isConnected()) {
    headers[API_KEY_NAME] = authentication.getToken()
  }
  return { ...config, headers }
}

export const api = axios.create()
api.interceptors.request.use(setHeaders)
api.interceptors.response.use(handleSuccess, handleError)

function Put(url, params) {
  return api.put(`${apiUrl}${url}`, params)
}

function Patch(url, params) {
  return api.patch(`${apiUrl}${url}`, params)
}

function Delete(url) {
  return api.delete(`${apiUrl}${url}`)
}

function Post(url, params) {
  return api.post(`${apiUrl}${url}`, params)
}

function buildRequestGetParams(params, mainSegment) {
  let requestUrl = ''
  if (params) {
    Object.keys(params).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key]
        if (value !== null && value !== undefined) {
          requestUrl += `/${key}/${value}`
        }
      }
    })
  } else {
    console.warn(`${text.LOGS} no params for ${mainSegment} WS`)
  }
  return requestUrl
}

function Get(mainSegment, params, action) {
  const paramsrequestUrl = buildRequestGetParams(params, mainSegment)
  const completeUrl = `${apiUrl}${mainSegment}${paramsrequestUrl}${action}`
  return api.get(completeUrl)
}

const xhr = {
  Put,
  Post,
  Get,
  Delete,
  Patch,
  buildRequestGetParams,
}

export default xhr
